import { useEffect, useState } from 'react'
import api from '../../services/api'

export default function CompetitionCalendar({competition_id}) {
  const [calendar, setCalendar] = useState<any>([])
  const [title, setTitle] = useState<any>('')

  const getCalendar = async () => {
    await api.get(`/api/calendars/${competition_id}`).then((response) => {
      const data = response.data
      setCalendar(data)
      setTitle('Calendário 2024 - 6ª Edição')
    })
  }

  useEffect(() => {
    getCalendar()
  }, [])

  return (
    <>
      {calendar.length > 0 ?
          <>
            <div className="col-md-12 my-1">
              <button type="button" className="btn btn-block btn-warning" data-toggle="modal" data-target={`#modal-${competition_id}`}>
                <i className="fa fa-calendar" aria-hidden="true"></i> &nbsp; Calendário
              </button>
            </div>

            <div className="modal fade" id={`modal-${competition_id}`} role="dialog" aria-labelledby={`modal-${competition_id}Label`} aria-hidden="true">
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id={`modal-${competition_id}Label`}>CT 12</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <table className="table table-striped" >
                      <thead>
                      <tr>
                        <th scope="col" colSpan={4}>{title}</th>
                      </tr>
                      <tr>
                        <th scope="col">Etapa</th>
                        <th scope="col">Data</th>
                        <th scope="col">Equipe</th>
                        <th scope="col">Clube</th>
                      </tr>
                      </thead>
                      <tbody>
                      {calendar.map((res, index) =>
                          <tr key={`calendar-${res.id}`} >
                            <th scope='row'>{res.stage}</th>
                            <td>{res.dates}</td>
                            <td>{res.team}</td>
                            <td>{res.club}</td>
                          </tr>
                      )}
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-dismiss="modal">Fechar</button>
                  </div>
                </div>
              </div>
            </div>
          </>
          :null
      }
    </>
  )
}