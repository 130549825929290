import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import api from '../../services/api'
import { DataTableOptions } from '../../components/dataTableOptions'
import {useNavigate} from "react-router";

export default function ListingAthletes() {
  const navigate = useNavigate()
  const [athletes, setAthletes] = useState<any>([])

  const user = JSON.parse(localStorage.getItem('token') || "").user

  const getAthletes = async () => {
    await api.get('/api/athletes').then((response) => {
      const data = response.data.data
      setAthletes(data)
    })
  }

  useEffect(() => {
    if(user?.organizer === "0" && user?.role === "athlete"){
      localStorage.setItem('hasError', JSON.stringify({title:"Atenção!" ,message:"Você não tem permissão. ",type: 'danger'}));
      return navigate("/home");
    }

    getAthletes()
  }, [])

  // { name: "Name", options: { filterOptions: { fullWidth: true } } },
  const columns: any = [
    {
      name: 'full_name', label: 'Nome', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'birth_date_formated', label: 'Data nasc', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'category', label: 'CATEGORIA', options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <span>{value.description}</span>
        )
      },
    },
    {
      name: 'team', label: 'EQUIPE', options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <span>{value.fantasia}</span>
        )
      },
    },
    {
      name: 'phone', label: 'TELEFONE', options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: '', label: '', options: {
        filter: false,
        false: true,
        customBodyRenderLite: index => {return (
            <>
              <Link className='btn btn-sm btn-warning' to={`/adm/athletes/update/${athletes[index]?.id}`} title="Editar"><i className="fa fa-pencil" aria-hidden="true"></i> </Link>
            </>
          )
        },
      },
    },
  ]

  const options: any = DataTableOptions

  return (
    <>
      <h2>Atletas</h2>

      <div className='mb-4'>
        {/*<Link className='btn btn-success mb-3' to='/adm/athletes/create'>Cadastrar atleta</Link>*/}

        <MUIDataTable
          title={''}
          data={athletes}
          columns={columns}
          options={options}
        />
      </div>

    </>
  )
}