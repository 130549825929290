import React, { useState } from 'react'
import api from '../../services/api'
import './SpreadsheetPrinting.css'
import html2pdf from 'html2pdf.js'
export default function SpreadsheetPrinting({ classID }) {
  const [spreadsheet, setSpreadsheet] = useState<any>({})
  const [loading, setLoading] = useState<any>(true)

  const handleGeneratePDF = () => {
    const element = document.getElementById('sheet0')
    var opt = {
      filename: 'planilha.pdf',
      html2canvas: {
        // dpi: 192,
        scale: 2,
      },
      jsPDF: {format: 'a4', orientation: 'landscape' },
    }

    html2pdf().set(opt).from(element).toPdf().get('pdf').then(function(pdfObj) {
      // pdfObj has your jsPDF object in it, use it as you please!
      // For instance (untested):
      pdfObj.autoPrint();
      window.open(pdfObj.output('bloburl'), '_blank')
    })
  }

  const printPDF = () => {
    api.post('/api/spreadsheetPrinter/' + classID)
      .then(resposta => {
        setSpreadsheet(resposta.data.data)
        setLoading(false)
      }).then(res =>{
      setTimeout(function() {handleGeneratePDF()}, 300);
    })
  }

  return (
    <>
      <button className='btn btn-sm btn-info' onClick={printPDF}> <i className="fa fa-print" aria-hidden="true"></i> &nbsp; Imprimir planilha</button>

      {!loading ?
        <div style={{display:'none'}}>
          <table cellPadding='0' cellSpacing='0' id='sheet0' className='sheet0'>
          <col className='col0' />
          <col className='colSeries' />
          <col className='colAthletes' />
          <col className='colCategory' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colShots' />
          <col className='colTotal' />
          {/*<col className='colTotal' />*/}
          <col className='colChecked' />
          <tbody>
          <tr>
            <td colSpan={32}>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colSpan={2} className='styleLogos '>
              <div style={{ position: 'relative' }}>
                {/*<img className={'imgTeam'} src={spreadsheet.team_image} />*/}
              </div>
            </td>
            <td colSpan={25}>&nbsp;</td>
            <td colSpan={4} className='styleLogos '>
              <div style={{ position: 'relative' }}>
                <img style={{ position: 'absolute', zIndex: '1', right: '0px', top: '12px', height: '60px' }}
                     src='/images/interclubes.svg' />
              </div>
            </td>
          </tr>

          <tr>
            <td className='styleTitulo' colSpan={32} rowSpan={2}>
              <span className='stylePlanilha'>PLANILHA DE PROVA</span> <br />
              <span>{spreadsheet.test}</span> <br />
              <span className={'styleCompetitions'}>{spreadsheet.competition}</span>
            </td>
          </tr>

          <tr>
            <td colSpan={32}>&nbsp;</td>
          </tr>

          <tr>
            <td colSpan={5}>&nbsp;</td>
            <td colSpan={24} rowSpan={2}>&nbsp;</td>
            <td className='styleData' colSpan={2}>DATA {spreadsheet.date} </td>
          </tr>
          <tr>
            <td colSpan={28}>&nbsp;</td>
            <td style={{ textAlign: 'end' }} colSpan={2} rowSpan={2}><span
              className='styleTurma'>{spreadsheet.class}</span></td>
          </tr>

          <tr>
            <td>&nbsp;</td>
            <td className='marginTopp' colSpan={30}>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          {spreadsheet?.athletes.map((athlete, index) =>
            <>
              <tr className='linha'>
                <td className=''>&nbsp;</td>
                <td className='styleLinhas bordas'>Série</td>
                <td className='styleLinhas bordas'>Atirador</td>
                <td className='styleLinhas bordas'>Categoria</td>
                <td className='styleLinhas bordas'>1</td>
                <td className='styleLinhas bordas'>2</td>
                <td className='styleLinhas bordas'>3</td>
                <td className='styleLinhas bordas'>4</td>
                <td className='styleLinhas styleBordaDupla'>5</td>
                <td className='styleLinhas bordas'>6</td>
                <td className='styleLinhas bordas'>7</td>
                <td className='styleLinhas bordas'>8</td>
                <td className='styleLinhas bordas'>9</td>
                <td className='styleLinhas styleBordaDupla'>10</td>
                <td className='styleLinhas bordas'>11</td>
                <td className='styleLinhas bordas'>12</td>
                <td className='styleLinhas bordas'>13</td>
                <td className='styleLinhas bordas'>14</td>
                <td className='styleLinhas styleBordaDupla'>15</td>
                <td className='styleLinhas bordas'>16</td>
                <td className='styleLinhas bordas'>17</td>
                <td className='styleLinhas bordas'>18</td>
                <td className='styleLinhas bordas'>19</td>
                <td className='styleLinhas styleBordaDupla'>20</td>
                <td className='styleLinhas bordas'>21</td>
                <td className='styleLinhas bordas'>22</td>
                <td className='styleLinhas bordas'>23</td>
                <td className='styleLinhas bordas'>24</td>
                <td className='styleLinhas styleBordaDupla'>25</td>
                <td className='styleLinhas bordas'>Total</td>
                {/*<td className='styleLinhas bordas'>Soma</td>*/}
                <td className='styleLinhas marginRi bordas'>Visto</td>
              </tr>
              <tr className='linha'>
                <td className=''>&nbsp;</td>
                <td className='styleAthleteSeries bordas' rowSpan={2}>1</td>
                <td className='styleAthleteSeries borderLef'>{athlete.name}</td>
                <td className='styleCategory' rowSpan={2}>{athlete.category} </td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='styleBordaDupla' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='styleBordaDupla' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='styleBordaDupla' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='styleBordaDupla' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                <td className='styleBordaDupla' rowSpan={2}></td>
                <td className='bordas' rowSpan={2}></td>
                {/*<td className='bordas' rowSpan={2}></td>*/}
                <td className='bordas marginRi' rowSpan={2}></td>
              </tr>
              <tr className='linha'>
                <td className=''>&nbsp;</td>
                {/*<td className='styleAthleteSeries bordas'>2</td>*/}
                <td className='styleAthleteSeries bordas font8'>{athlete.team}</td>
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='styleBordaDupla'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='styleBordaDupla'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='styleBordaDupla'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='styleBordaDupla'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='bordas'></td>*/}
                {/*<td className='styleBordaDupla'></td>*/}
                {/*<td className='bordas'></td>*/}
              </tr>
            </>)}

          <tr>
            <td colSpan={32}>&nbsp;</td>
          </tr>

          <tr>
            <td colSpan={32}>&nbsp;</td>
          </tr>

          <tr>
            <td colSpan={2}>&nbsp;</td>
            <td colSpan={3} style={{ textAlign: 'center', borderTop: '1px solid black' }}>Visto do Juiz Soltador</td>
            <td colSpan={15}>&nbsp;</td>
            <td colSpan={10} style={{ textAlign: 'center', borderTop: '1px solid black' }}>Visto do Juiz Apontador</td>
            <td colSpan={2}>&nbsp;</td>
          </tr>

          <tr>
            <td colSpan={32}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={26}>&nbsp;</td>
            <td colSpan={5} style={{ textAlign: 'right' }}><span
              style={{ border: '1px solid black', textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span> Lançada
            </td>
            <td colSpan={1}>&nbsp;</td>
          </tr>
          </tbody>
        </table>
        </div>
        : null
      }
    </>
  )
}



