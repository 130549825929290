import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import api from '../../services/api'
import Swal from 'sweetalert2'

export default function Result() {

  const { id } = useParams()

  // const [results, setResults] = useState<IClass[] | any >([])
  const [title, setTitle] = useState<any>('')
  const [results, setResults] = useState<any>([])
  const [categories, setCategories] = useState<any>([])
  const [teams, setTeams] = useState<any>([])

  const [resultsCategories, setResultsCategories] = useState<any>({})
  const [resultsTeams, setResultsTeams] = useState<any>({})
  const navigate = useNavigate()
  const athleteLogado = JSON.parse(localStorage.getItem('token') || '').user.athlete?.id
  const [loading, setLoading] = useState(true)
  const [showData, setShowData] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      // axios.get<ITest[]>('http:///api/classes')
      try {
        await api.get('/api/getResults/' + id)
          .then(resposta => {
            if (resposta.data.data.test[0].status == 'finished') {
              Swal.fire({
                title: 'Atenção!',
                text: 'Etapa finalizada, resultados disponíveis no ranking geral.',
                icon: 'info',
                timer: 5000,
              }).then((result) => {
                return navigate(`/competition/${resposta.data.data.test[0].competition_id}/results`)
              })
            } else {
              setTitle(resposta.data.data.title)
              setResults(resposta.data.data.results)
              setCategories(resposta.data.data.categories)
              setTeams(resposta.data.data.teams)
              setResultsTeams(resposta.data.data.teams_result)

              for (const categoria of resposta.data.data.categories) {
                const resultadosDaCategoria = resposta.data.data.results.filter(resultado => resultado.category_id === categoria.id)
                setResultsCategories(prevResultsCati => ({
                  ...prevResultsCati,
                  [categoria.id]: resultadosDaCategoria,
                }))
              }

              setShowData(true)
            }

          }).then(resposta => {
            setLoading(false)
          })
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }

      // console.log(resultsTeams)
    }

    fetchData().then(() => {
      // Re-render component after fetchData is finished
    })

  }, [])

  const getTeamName = (id) => {
    const team = teams.filter(timess => timess.id == id )
    return team[0].team.fantasia
  }

  const getCatName = (id) => {
    if(id == 1){
      return "A"
    }else if(id == 2){
      return "B"
    }else if(id == 3){
      return "C"
    }else{
      return "Damas"
    }
  }

  return (
    <>
      {showData ? (
        <>
          {!loading ? (
              <>
                <div className='row'>
                  <div className='col-md-12'>
                    <h3 className='mb-2'>
                      <b>{ title }</b>
                    </h3>
                  </div>
                </div>

                <ul className='nav nav-tabs justify-content-center' role='tablist'>
                  <li className='nav-item'>
                    <a className='nav-link active' data-toggle='tab' href='#generalResult'>Resultado Geral</a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-toggle='tab' href='#resultForCategory'>Resultado por Categoria</a>
                  </li>
                  {/*<li className='nav-item'>*/}
                  {/*  <a className='nav-link' data-toggle='tab' href='#resultForTeam'>Resultado por Equipe</a>*/}
                  {/*</li>*/}
                </ul>

                <div className='tab-content'>
                  <div id='generalResult' className='container tab-pane active'><br />
                    <div className='accordion' id='accordionExample'>
                      <div className='card'>
                        <div className='card-header' id='headingOne'>
                          <h2 className='mb-0'>
                            <button className='btn btn-link btn-block text-left' type='button' data-toggle='collapse'
                                    data-target='#collapseOne' aria-expanded='true' aria-controls='collapseOne'>
                              <b>Resultado Geral</b>
                            </button>
                          </h2>
                        </div>

                        <div id='collapseOne' className='collapse show' aria-labelledby='headingOne'
                             data-parent='#accordionExample'>
                          <div className='card-body'>
                            <table className='table table-striped table-hover'>
                              <thead>
                              <tr>
                                <th scope='col'>Posição</th>
                                <th scope='col'>Nome</th>
                                <th scope='col'>Cat</th>
                                <th scope='col'>Pontuação</th>
                              </tr>
                              </thead>
                              <tbody>
                              {results != undefined && results.length > 0 ?
                                (<>
                                    {results.map((res, index) =>
                                      <>
                                        <tr key={`geral-${index}`}
                                            className={athleteLogado == res.athlete_id ? 'table-warning' : ''}
                                            data-toggle='collapse' data-target={`#collapse0_${index}`}
                                            aria-expanded='false'
                                            aria-controls={`collapse0_${index}`}>
                                          <th scope='row'> {index + 1 <= 9 ? ` ` : ''} {index + 1}</th>
                                          <td>{res.name}</td>
                                          <td>{getCatName(res.category_id)}</td>
                                          <td
                                            title={`${!res.motivoDesemp ? '' : `Motivo desempate: ${res.motivoDesemp}\n`}${res.motivoDesemp == "CountBack"? `CountBack: ${res.countBack + 1}` :`Data Nasc: ${res.athlete.birth_date_formated}` }`}>{res.resultTotalSeries}</td>
                                        </tr>
                                        {/* <tr>
                                                  <td colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                                      <div id={`collapse0_${index}`} className="collapse table-responsive" aria-labelledby="heading0_1">
                                                          <div className="row">
                                                              <table className="table table-striped table-hover">
                                                                  <thead>
                                                                      <tr>
                                                                          <th scope="col">Série</th>
                                                                          <th scope="col">1</th>
                                                                          <th scope="col">2</th>
                                                                          <th scope="col">3</th>
                                                                          <th scope="col">4</th>
                                                                          <th scope="col">5</th>
                                                                          <th scope="col">6</th>
                                                                          <th scope="col">7</th>
                                                                          <th scope="col">8</th>
                                                                          <th scope="col">9</th>
                                                                          <th scope="col">10</th>
                                                                          <th scope="col">11</th>
                                                                          <th scope="col">12</th>
                                                                          <th scope="col">13</th>
                                                                          <th scope="col">14</th>
                                                                          <th scope="col">15</th>
                                                                          <th scope="col">16</th>
                                                                          <th scope="col">17</th>
                                                                          <th scope="col">18</th>
                                                                          <th scope="col">19</th>
                                                                          <th scope="col">20</th>
                                                                          <th scope="col">21</th>
                                                                          <th scope="col">22</th>
                                                                          <th scope="col">23</th>
                                                                          <th scope="col">24</th>
                                                                          <th scope="col">25</th>
                                                                          <th scope="col">Total</th>
                                                                      </tr>
                                                                  </thead>
                                                                  <tbody>

                                                                  {res.series.map((ser) => {
                                                                      <tr>
                                                                          <th scope="row">{JSON.stringify(ser)}</th>
                                                                          <td>1</td>
                                                                          <td>1</td>
                                                                          <td>1</td>
                                                                      </tr>
                                                                   })}

                                                                  </tbody>
                                                              </table>
                                                          </div>
                                                      </div>
                                                  </td>
                                              </tr> */}
                                      </>,
                                    )}
                                  </>
                                ) :
                                (<tr>
                                    <td colSpan={4} className='text-center'>
                                      Nenhum resultado lançado
                                    </td>
                                  </tr>
                                )}

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id='resultForCategory' className='container tab-pane fade'><br />
                    <div className='accordion' id='accordionCategory'>

                      {categories.map((category, index) =>
                        <div key={`${index}`} className='card'>
                          <div className='card-header' id={`headingCategory-${category.description}`}>
                            <h2 className='mb-0'>
                              <button className='btn btn-link btn-block text-left collapsed' type='button'
                                      data-toggle='collapse'
                                      data-target={`#collapseCategory-${category.description}`} aria-expanded='false'
                                      aria-controls={`collapseCategory-${category.description}`}>
                                <b>Categoria {category.description}{category.id == 4 ?'amas':''}</b>
                              </button>
                            </h2>
                          </div>
                          <div id={`collapseCategory-${category.description}`}
                               className={index == 0 ? 'collapse show' : 'collapse'}
                               aria-labelledby={`headingCategory-${category.description}`}
                               data-parent='#accordionCategory'>
                            <div className='card-body'>
                              <table className='table table-striped table-hover'>
                                <thead>
                                <tr>
                                  <th scope='col'>Posição</th>
                                  <th scope='col'>Nome</th>
                                  <th scope='col'>Pontuação</th>
                                </tr>
                                </thead>
                                <tbody>
                                {resultsCategories[category.id] != undefined && resultsCategories[category.id].length > 0 ?
                                  (<>
                                      {resultsCategories[category.id].map((res, index) =>
                                        <tr key={`categories-${index}`}
                                            className={athleteLogado == res.athlete_id ? 'table-warning' : ''}>
                                          <th scope='row'> {index + 1 <= 9 ? ` ` : ''} {index + 1}</th>
                                          <td>{res.name}</td>
                                          <td
                                            title={`${!res.motivoDesemp ? '' : `Motivo desempate: ${res.motivoDesemp}\n`}${res.motivoDesemp == "CountBack"? `CountBack: ${res.countBack + 1}` :`Data Nasc: ${res.athlete.birth_date_formated}` }`}>{res.resultTotalSeries}</td>
                                        </tr>,
                                      )}
                                    </>
                                  ) :
                                  (<tr>
                                      <td colSpan={3} className='text-center'>
                                        Nenhum resultado lançado
                                      </td>
                                    </tr>
                                  )}

                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>,
                      )}

                    </div>
                  </div>

                  {/*<div id='resultForTeam' className='container tab-pane fade'><br />*/}
                  {/*  <div className='accordion' id='accordionFromTeam'>*/}
                  {/*    {resultsTeams.map((team, index) =>*/}
                  {/*        <div key={`${index}`} className='card'>*/}
                  {/*          <div className='card-header' id={`headingTeam-${index}`}>*/}
                  {/*            <h2 className='mb-0'>*/}
                  {/*              <button className='btn btn-link btn-block text-left collapsed' type='button'*/}
                  {/*                      data-toggle='collapse' data-target={`#collapseTeam-${index}`} aria-expanded='false'*/}
                  {/*                      aria-controls={`collapseTeam-${index}`}>*/}
                  {/*                <b>{getTeamName(team.time)}*/}
                  {/*                  <span*/}
                  {/*                    title={`Soma dos melhores resutados: ${team.soma} \nInscrições: ${team.ins}\nReinscrições: ${team.re}`}> &nbsp;{team.soma}  &nbsp;</span>*/}
                  {/*                </b>*/}
                  {/*              </button>*/}
                  {/*            </h2>*/}
                  {/*          </div>*/}
                  {/*          <div id={`collapseTeam-${index}`} className={index == 0 ? 'collapse show' : 'collapse'}*/}
                  {/*               aria-labelledby={`headingTeam-${index}`}*/}
                  {/*               data-parent='#accordionFromTeam'>*/}
                  {/*            <div className='card-body'>*/}
                  {/*              <table className='table table-striped table-hover'>*/}
                  {/*                <thead>*/}
                  {/*                <tr>*/}
                  {/*                  <th scope='col'>Posição</th>*/}
                  {/*                  <th scope='col'>Nome</th>*/}
                  {/*                  <th scope='col'>Cat</th>*/}
                  {/*                  <th scope='col'>Pontuação</th>*/}
                  {/*                </tr>*/}
                  {/*                </thead>*/}
                  {/*                <tbody>*/}
                  {/*                {team.resultados != undefined && team.resultados.length > 0 ?*/}
                  {/*                  (<>*/}
                  {/*                      {team.resultados.map((res, index) =>*/}
                  {/*                        <>*/}
                  {/*                          <tr key={`teams-${index}`}*/}
                  {/*                              className={`${athleteLogado == res.athlete_id ? 'table-warning' : ''} ${res.class_id >= 480 && res?.melhor ? 'table-info' : ''}`}>*/}
                  {/*                            <th scope='row'> {index + 1 <= 9 ? ` ` : ''} {index + 1}</th>*/}
                  {/*                            <td>{res.name}</td>*/}
                  {/*                            <td>{getCatName(res.category_id)}</td>*/}
                  {/*                            <td*/}
                  {/*                              title={`${!res.motivoDesemp ? '' : `Motivo desempate: ${res.motivoDesemp}\n`}${res.motivoDesemp == "CountBack"? `CountBack: ${res.countBack + 1}` :`Data Nasc: ${res.athlete.birth_date_formated}` }`}>{res.resultTotalSeries}</td>*/}
                  {/*                          </tr>*/}

                  {/*                          {res.class_id < 480 && ((team.resultados.length >= 3 && index == 3) || (team.resultados.length < 3 && (index + 1) == team.resultados.length)) ? (*/}
                  {/*                            <tr className={'table-success'}>*/}
                  {/*                              <td colSpan={2}></td>*/}
                  {/*                              <th>Total</th>*/}
                  {/*                              <th>{team.soma}</th>*/}
                  {/*                            </tr>*/}
                  {/*                          ) : null}*/}
                  {/*                        </>,*/}
                  {/*                      )}*/}

                  {/*                    </>*/}
                  {/*                  ) :*/}
                  {/*                  (<tr>*/}
                  {/*                      <td colSpan={4} className='text-center'>*/}
                  {/*                        Nenhum resultado lançado*/}
                  {/*                      </td>*/}
                  {/*                    </tr>*/}
                  {/*                  )}*/}

                  {/*                </tbody>*/}
                  {/*              </table>*/}
                  {/*            </div>*/}
                  {/*          </div>*/}
                  {/*        </div>*/}
                  {/*    )}*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>

                <div className='row py-4'>
                  <div className='col-md-12  text-right'>
                    <button className='btn btn-danger btn-floating' onClick={() => navigate(-1)}>Voltar<br /></button>
                  </div>
                </div>
              </>
            )
            : (<div className='loading'>

              <span className='loader'></span>
            </div>)
          }
        </>
      ) : null}
    </>

  )
}
