import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../services/api'
import './ckeckbox.css'
import Swal from 'sweetalert2'
import Select from 'react-select'
import { SelectTheme } from '../../components/selectTheme'

export default function Spreadsheet() {
  const { id } = useParams()
  const editing = window.location.pathname.indexOf('update') > -1
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [series, setSeries] = useState<any>([])
  const [classTitle, setClassTitle] = useState<any>('')
  const [arrayCategories, setArrayCategories] = useState([] as any)
  const [arrayTeams, setArrayTeams] = useState([] as any)
  const [user, setUser] = useState({}  as any);


  useEffect(() => {

    setUser(JSON.parse(localStorage.getItem('token') || "").user)

    setLoading(true)
    categories()
    teams()

    api.get((!editing ? '/api/class/getAthletes/' : '/api/class/update/') + id)
      .then(resposta => {
        setSeries(resposta.data.data.athletes)
        setClassTitle(resposta.data.data.classTitle)
        setLoading(false)
      }).catch(erro => {
        setLoading(false)
        console.log(erro.response)
        Swal.fire({
          title: 'Erro!',
          text: erro.response.data.sqlMessage,
          icon: 'error',
          timer: 5000,
        }).then((result) => {
          return navigate(-1)
        })
      },
    )
  }, [])

  async function categories(value = '') {
    const response = await api.get(`/api/categories`)
    const result = await response.data
    return setArrayCategories(
      await result.data.map((category) => ({
        label: category.description,
        value: category.id,
      })),
    )
  }

  async function teams(value = '') {
    const response = await api.get(`/api/teams`)
    const result = await response.data
    return setArrayTeams(
      await result.data.map((club) => ({
        label: club.fantasia,
        value: club.id,
      })),
    )
  }

  const editAthlete = (atlete_id) => {
    setSeries(
      series.map((serie, index) => {
        // console.log(serie.atlete_id, atlete_id)
        if (serie.atlete_id == atlete_id) {
          serie.editing = !serie.editing
        }
        return serie
      }),
    )
  }

  const updateTeamAtlete = (value, atlete_id) => {
    setSeries(
      series.map((serie, index) => {
        // console.log(serie.atlete_id, atlete_id)
        if (serie.atlete_id == atlete_id) {
          serie.athlete.team_id = value
        }
        return serie
      }),
    )
  }

  const updateCategoryAtlete = (value, atlete_id) => {
    setSeries(
      series.map((serie, index) => {
        // console.log(serie.atlete_id, atlete_id)
        if (serie.atlete_id == atlete_id) {
          serie.athlete.category_id = value
        }
        return serie
      }),
    )
  }

  const controlsCheckbox = (infos, marcarTudo, desistencia = false) => {
    setSeries(
      series.map((serie) => {
        if (serie.atlete_id == infos.atlete_id) {
          serie.series.map((item) => {
            if (item.serie == infos.serie) {
              for (let i = 0; i < 25; i++) {
                item.shots[i + 1] = (marcarTudo ? 1 : 0)
              }

              item.total = (marcarTudo ? 25 : 0)
            }
          })
        }

        return serie
      }),
    )
  }

  const changeStatusCheckbox = (e) => {
    let id = e.target.id.split('-')
    setSeries(
      series.map((serie, idx) => {
        if (serie.atlete_id == id[0]) {
          serie.series.map((item, idx) => {
            if (item.serie == id[1]) {
              item.shots[id[2]] = (!item.shots[id[2]] ? 1 : 0)

              item.total = Object.values(item.shots).reduce((total: any, numero: any) => total + numero, 0)
            }
          })
        }

        return serie
      }),
    )
  }

  const editingAthlete = () => {
    const editing = series.reduce((acc, serie) => {
      return acc + (serie.editing === true ? 1 : 0)
    }, 0)

    if (editing != 0) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Salve todos os atletas para lançar o resultado.',
        icon: 'warning',
      })
      return false
    }
    return true
  }

  const save = (action) => {
    setLoading(true)
    if (editingAthlete()) {
      if (action === 'store') {
        api.post('/api/series', series)
          .then(resposta => {
            if (resposta.status == 201) {
              setLoading(false)
              Swal.fire({
                title: 'Sucesso!',
                text: 'Resultado lançado com sucesso',
                icon: 'success',
              }).then((result) => {
                return navigate(-1)
              })

            } else {
              setLoading(false)
              Swal.fire({
                title: 'Erro!',
                text: JSON.stringify(resposta),
                icon: 'error',
              })
            }
          })
      } else if (action === 'update') {
        api.put('/api/series/' + id, series)
          .then(resposta => {
            setLoading(false)
            if (resposta.status == 200) {
              Swal.fire({
                title: 'Sucesso!',
                text: 'Resultado atualizado com sucesso',
                icon: 'success',
              }).then((result) => {
                return navigate(-1)
              })

            } else {
              setLoading(false)
              Swal.fire({
                title: 'Erro!',
                text: JSON.stringify(resposta),
                icon: 'error',
              })
            }
          })
      }
    }
  }

  return (
    <>
      {loading ?  (<div className="loading">
                    <span className="loader"></span>
                  </div>)
          : null
      }
      <div className='row my-4'>
        <div className='col-md-12'>
          <h3 className='mb-2'>
            <b>Planilha <span className='text-muted'> ({classTitle})</span></b>
          </h3>
        </div>

        <div className=''>
          <table className='table table-striped'>
            <tbody>
            {series.map((serie, idx) => {
              return <>
                <tr id={`${serie.atlete_id}-atlete`} key={`at-${serie.atlete_id}`}>
                  <th></th>
                  <th colSpan={27} className='pt-1 pb-1'>
                    <div className='form-row'>
                      <div className='mr-2 mtp-auto'>
                        {serie.name}
                      </div>
                      <div className=' col-md-2'>
                        <Select
                          options={arrayCategories as any}
                          name='category'
                          id='category'
                          onChange={(data: any) => {
                            updateCategoryAtlete(data.value, serie.atlete_id)
                          }}
                          noOptionsMessage={() => 'Nenhum registro encontrado'}
                          isDisabled={!serie.editing}
                          value={arrayCategories.find(option => option.value == serie.athlete.category_id) || ''}
                          placeholder='Sua Categoria'
                          theme={(theme) => (SelectTheme(theme))}
                        />

                      </div>

                      <div className=' col-md-3'>
                        <Select
                          options={arrayTeams}
                          name='team'
                          id='team'
                          onChange={(data: any) => {
                            updateTeamAtlete(data.value, serie.atlete_id)
                          }}
                          noOptionsMessage={() => 'Nenhum registro encontrado'}
                          isDisabled={!serie.editing}
                          value={arrayTeams.find(option => option.value == serie.athlete.team_id) || ''}
                          placeholder='Sua equipe'
                          theme={(theme) => (SelectTheme(theme))}
                        />
                      </div>

                      {(user?.role === 'coordinator' || user?.role === 'admin') || ((serie.athlete.category_id === 4 && !serie.editing) || serie.editing)?  (
                          <div className=' col-md-2 mtp-auto'>
                            <button className={`btn btn-sm ${!serie.editing ? 'btn-info' : 'btn-success'}`} onClick={() => {editAthlete(serie.atlete_id)}}>{!serie.editing ? 'Editar' : 'Salvar'}<br /></button>
                          </div>
                        ) : null}

                    </div>

                  </th>
                </tr>
                <tr>
                  <th scope='col'>Série</th>
                  <th scope='col'>&nbsp;&nbsp;1</th>
                  <th scope='col'>&nbsp;&nbsp;2</th>
                  <th scope='col'>&nbsp;&nbsp;3</th>
                  <th scope='col'>&nbsp;&nbsp;4</th>
                  <th scope='col' style={{ borderRight: '4px double #dee2e6', }}>&nbsp;&nbsp;5</th>

                  <th scope='col'>&nbsp;&nbsp;6</th>
                  <th scope='col'>&nbsp;&nbsp;7</th>
                  <th scope='col'>&nbsp;&nbsp;8</th>
                  <th scope='col'>&nbsp;&nbsp;9</th>
                  <th scope='col' style={{ borderRight: '4px double #dee2e6', }}>10</th>

                  <th scope='col'>11</th>
                  <th scope='col'>12</th>
                  <th scope='col'>13</th>
                  <th scope='col'>14</th>
                  <th scope='col' style={{ borderRight: '4px double #dee2e6', }}>15</th>

                  <th scope='col'>16</th>
                  <th scope='col'>17</th>
                  <th scope='col'>18</th>
                  <th scope='col'>19</th>
                  <th scope='col' style={{ borderRight: '4px double #dee2e6', }}>20</th>

                  <th scope='col'>21</th>
                  <th scope='col'>22</th>
                  <th scope='col'>23</th>
                  <th scope='col'>24</th>
                  <th scope='col'>25</th>
                  <th scope='col'>Total</th>
                </tr>
                {serie.series.map(ser => {
                  return <tr key={`se-${ser.serie}-at-${serie.atlete_id}`}>
                    <th scope='row'>
                      <div className='dropdown'>
                        <button className='btn btn-sm dropdown-toggle' type='button' data-toggle='dropdown' aria-expanded='false'>
                          <b>{ser.serie}</b>
                        </button>
                        <div className='dropdown-menu'>
                          <a className='dropdown-item'
                             onClick={(e) => controlsCheckbox({ atlete_id: serie.atlete_id, serie: ser.serie }, true)}>Marcar tudo</a>
                          <a className='dropdown-item'
                             onClick={(e) => controlsCheckbox({ atlete_id: serie.atlete_id, serie: ser.serie }, false)}>Desmarcar tudo</a>
                           {/*<a className="dropdown-item" onClick={(e) => controlsCheckbox({ atlete_id: serie.atlete_id, serie: ser.serie }, false, true)} >Desistencia</a> */}
                        </div>
                      </div>
                    </th>

                    {Object.keys(ser.shots).map((shot) => {
                      return <td style={(parseInt(shot) % 5 == 0) ? { borderRight: '4px double #dee2e6' } : {}}>
                        <label className='conainer' title={shot}>
                          <input type='checkbox' id={`${serie.atlete_id}-${ser.serie}-${shot}`}
                                 checked={ser.shots[shot]}
                                 onChange={(e) => changeStatusCheckbox(e)}
                                 aria-label='Checkbox for following text input' />
                          <span className='checkmark'></span>
                        </label>
                      </td>
                    })}
                    <td><b>{ser.total}</b></td>
                  </tr>
                })}
              </>
            })}
            </tbody>
          </table>

        </div>
        <div className='row py-2'>
          <div className='col-md-12  text-right'>
            <button className='btn btn-success mr-1' onClick={() => { save(!editing ? 'store' : 'update')}}>Salvar<br /></button>
            <button className='btn btn-danger' onClick={() => navigate(-1)}>Voltar<br /></button>
          </div>
        </div>
      </div>
    </>
  )
}